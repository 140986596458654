import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import AuthAdminRoute from "../hocs/AuthAdminRoute";
import AuthRoute from "../hocs/AuthRoute";
import NonAuthRoute from "../hocs/NonAuthRoute";
import AuthProvider from "../hocs/AuthProviver";
import LoadingSpinner from "../components/common/LoadingSpinner";
import VideoPage from "../containers/VideoPage";
const AdminDocuments = lazy(() => import("../containers/AdminDocuments"));
const AdminMyDetails = lazy(() => import("../containers/AdminMyDetails"));
const AdminSettings = lazy(() => import("../containers/AdminSettings"));
const Amenities = lazy(() => import("../containers/Amenities"));
const Apartment = lazy(() => import("../containers/Apartments"));
const DetailFloor = lazy(() =>
    import("../containers/Apartments/FloorView/DetailFloor")
);
const DetailProduct = lazy(() => import("../containers/DetailProduct"));
const Gallery = lazy(() => import("../containers/Gallery"));
const Home = lazy(() => import("../containers/Home"));
const Location = lazy(() => import("../containers/Location"));
const Login = lazy(() => import("../containers/Login"));
const ForgotPassword = lazy(() => import("../containers/ForgotPassword"));

const Routes = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <BrowserRouter>
                <AuthProvider>
                    <Switch>
                        <AuthAdminRoute
                            path="/admin/documents"
                            component={AdminDocuments}
                            exact
                        />
                        <AuthAdminRoute
                            path="/admin/my-details"
                            component={AdminMyDetails}
                            exact
                        />
                        <AuthAdminRoute
                            path="/admin/settings"
                            component={AdminSettings}
                            exact
                        />

                        <NonAuthRoute path="/home" component={Home} exact />
                        <NonAuthRoute path="/" component={Login} exact />
                        <NonAuthRoute
                            path="/forgot-password"
                            component={ForgotPassword}
                            exact
                        />
                        <AuthRoute
                            path="/apartments"
                            component={Apartment}
                            exact
                        />
                        <AuthRoute
                            path="/apartments/:id/level"
                            component={DetailFloor}
                            exact
                        />
                        <AuthRoute
                            path="/apartments/:id/detail"
                            component={DetailProduct}
                            exact
                        />
                        <AuthRoute
                            exact
                            path="/location"
                            component={Location}
                        />
                        <AuthRoute
                            path="/amenities"
                            component={Amenities}
                            exact
                        />
                        <AuthRoute
                            path="/master-plan"
                            component={Gallery}
                            exact
                        />
                        <AuthRoute path="/media" component={VideoPage} exact />
                    </Switch>
                </AuthProvider>
            </BrowserRouter>
        </Suspense>
    );
};

export default Routes;
