export const data = [
    {
        id: "1",
        icon: `${process.env.REACT_APP_MEDIA}/media/images/icons/my-detail.svg`,
        desc: "My Details",
        link: "/admin/my-details",
    },
    {
        id: "3",
        icon: `${process.env.REACT_APP_MEDIA}/media/images/icons/documents.svg`,
        desc: "Documents",
        link: "/admin/documents",
    },
    {
        id: "4",
        icon: `${process.env.REACT_APP_MEDIA}/media/images/icons/setting.svg`,
        desc: "Settings",
        link: "/admin/settings",
    },
];
