import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    authApi,
    signUpApi,
    getMe,
    updateProfile,
    changePass,
    configApi,
    logout,
    forgotPasswordApi,
    resetPasswordApi,
} from "../services/api/auth";
import { setToken, removeToken } from "../helpers/tokenMain";
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "../utils/common";

export const loginAction = createAsyncThunk("user/login", async (params) => {
    const response = await authApi(params);
    return response;
});

export const forgotPasswordAction = createAsyncThunk(
    "user/forgot-password",
    async (params) => {
        const response = await forgotPasswordApi(params);
        return response;
    }
);

export const resetPasswordAction = createAsyncThunk(
    "user/reset-password",
    async (params) => {
        const response = await resetPasswordApi(params);
        return response;
    }
);

export const signUpAction = createAsyncThunk("user/SIGNUP", async (params) => {
    const response = await signUpApi(params);
    return response;
});

export const getMeAction = createAsyncThunk("user/GETME", async () => {
    const response = await getMe();
    return response;
});

export const updateProfileAction = createAsyncThunk(
    "user/UpdateProfile",
    async (params) => {
        const response = await updateProfile(params);
        return response;
    }
);

export const changePassAction = createAsyncThunk(
    "user/CHANGEPASS",
    async (params) => {
        const response = await changePass(params);
        return response;
    }
);

export const getConfigAction = createAsyncThunk("user/CONFIG", async () => {
    const response = await configApi();
    return response;
});

export const logoutAction = createAsyncThunk("user/logout", async (data) => {
    const response = await logout(data);
    return response;
});

export const setEmailUserAction = createAsyncThunk(
    "user/set-email",
    async (data) => {
        return data;
    }
);

const User = createSlice({
    name: "user",
    initialState: {
        authentication: false,
        currentUser: {},
        current: {},
        mess: {},
        loading: false,
        error: {},
        message: "",
        success: false,
        closeModalRegister: false,
        isFirstAccess: true,
        config: {},
        updateProfileSuccess: false,
        loadingForm: false,
        emailUser: "",
        successResetPassword: false,
        messageResetPassword: "",
    },
    reducers: {
        handleCloseModalRegister(state) {
            state.closeModalRegister = true;
        },
        updateIsFirstAccess: (state) => {
            state.isFirstAccess = false;
        },
    },
    extraReducers: {
        [loginAction.pending]: (state) => {
            state.authentication = false;
        },
        [loginAction.rejected]: (state, action) => {
            state.authentication = false;
            state.isFirstAccess = false;
        },
        [loginAction.fulfilled]: (state, action) => {
            const { success, data, message } = action.payload;
            if (success) {
                state.loading = false;
                state.authentication = true;
                state.isFirstAccess = true;
                setToken(TOKEN_KEY, data?.accessToken);
                setToken(REFRESH_TOKEN_KEY, data?.refreshToken);
                window.location.replace("/home");
            } else {
                state.loading = false;
                state.authentication = false;
                state.isFirstAccess = false;
                state.message = message;
            }
        },

        [signUpAction.pending]: (state) => {
            // state.loading = true;
        },
        [signUpAction.rejected]: (state, action) => {
            state.message = action.payload.message;
            state.success = action.payload.success;
            // state.loading = false;
        },
        [signUpAction.fulfilled]: (state, action) => {
            state.success = action.payload.success;
            state.message = action.payload.message;
            // state.loading = false;
        },

        [getMeAction.pending]: (state) => {
            state.loading = true;
        },
        [getMeAction.rejected]: (state) => {
            state.loading = false;
            state.authentication = false;
            state.isFirstAccess = false;
        },
        [getMeAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.authentication = true;
            state.isFirstAccess = false;
            state.currentUser = action.payload.data;
        },

        [updateProfileAction.rejected]: (state, action) => {
            state.mess = action.payload;
        },
        [updateProfileAction.fulfilled]: (state, action) => {
            state.currentUser = action.payload.data;
            state.updateProfileSuccess = action.payload.success;
        },

        [changePassAction.rejected]: (state, action) => {
            state.mess = action.payload;
        },
        [changePassAction.fulfilled]: (state, action) => {
            state.mess = action.payload;
        },

        [getConfigAction.pending]: (state) => {
            state.loading = true;
        },
        [getConfigAction.rejected]: (state) => {
            state.loading = false;
        },
        [getConfigAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.config = action.payload.data;
        },
        // Logout
        [logoutAction.fulfilled]: (state, action) => {
            const { success } = action.payload;
            if (success) {
                removeToken(TOKEN_KEY);
                removeToken(REFRESH_TOKEN_KEY);
                window.location.replace("/");
            }
        },
        // forgot password
        [forgotPasswordAction.pending]: (state) => {
            state.loadingForm = true;
        },
        [forgotPasswordAction.rejected]: (state, action) => {
            state.messageResetPassword = action.payload.message;
            state.success = false;
            state.loadingForm = false;
        },
        [forgotPasswordAction.fulfilled]: (state, action) => {
            state.messageResetPassword = action.payload.message;
            state.success = action.payload.success;
            state.loadingForm = false;
        },
        // set Email
        [setEmailUserAction.pending]: (state) => {},
        [setEmailUserAction.rejected]: (state, action) => {},
        [setEmailUserAction.fulfilled]: (state, action) => {
            state.emailUser = action.payload;
        },
        // reset password
        [resetPasswordAction.pending]: (state) => {
            state.loadingForm = true;
        },
        [resetPasswordAction.rejected]: (state, action) => {
            state.messageResetPassword = action.payload.message;
            state.success = false;
            state.loadingForm = false;
        },
        [resetPasswordAction.fulfilled]: (state, action) => {
            state.messageResetPassword = action.payload.message;
            state.successResetPassword = action.payload.success;
            state.loadingForm = false;
        },
    },
});

export const { handleCloseModalRegister, updateIsFirstAccess } = User.actions;
const { reducer: UserReducer } = User;

export default UserReducer;
