import * as React from "react";

export function SelectField({
    label,
    error,
    nameDefault = "",
    register = () => {},
    name,
    options = [],
    className,
    ...rest
}) {
    return (
        <div className="main-select">
            {label && <label>{label}</label>}
            <select name={name} {...register(name)} {...rest}>
                {nameDefault && <option value="">{nameDefault}</option>}
                {options?.map((item, index) => {
                    return (
                        <option key={index} value={item?.value}>
                            {item?.name}
                        </option>
                    );
                })}
            </select>
            {error?.[name] && <span>{error?.[name]?.message}</span>}
        </div>
    );
}
