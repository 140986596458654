import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { data } from "./mock-data";

const settings = {
    slidesToShow: 6,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 7,
            },
        },
    ],
};

function VideoPage() {
    let slider1;
    let slider2;

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, []);

    const next = () => {
        slider2.slickNext();
    };
    const previous = () => {
        slider2.slickPrev();
    };

    return (
        <>
            <div className="amenities">
                <Slider asNavFor={nav2} ref={(c) => (slider1 = c)}>
                    {data &&
                        data.map((item, index) => (
                            <div
                                key={index}
                                className={
                                    item.src
                                        ? "amenities-content amenities-diff__content"
                                        : "amenities-content"
                                }
                            >
                                <div
                                    className={
                                        item.src
                                            ? "amenities-content__img amenities-content__video"
                                            : "amenities-content__img"
                                    }
                                >
                                    {item.src ? (
                                        <iframe
                                            src={item.src}
                                            frameBorder="0"
                                            allow="autoplay"
                                            webkitallowfullscreen="true"
                                            mozallowfullscreen="true"
                                            allowFullScreen
                                            title="video"
                                        ></iframe>
                                    ) : (
                                        <img src={item.srcImg} alt="img" />
                                    )}
                                </div>
                            </div>
                        ))}
                </Slider>
                <div className="amenities__slider">
                    <Slider
                        asNavFor={nav1}
                        ref={(slider) => (slider2 = slider)}
                        {...settings}
                    >
                        {data &&
                            data.map((item, index) => (
                                <div key={index}>
                                    <img src={item.srcImg} alt="img" />
                                </div>
                            ))}
                    </Slider>
                    <div className="amenities-btn-prev">
                        <button
                            className="amenities-btn__slider"
                            onClick={previous}
                        >
                            <img
                                src={`${process.env.REACT_APP_MEDIA}/media/images/icons/btn_arrow_left.svg`}
                                alt=""
                            />
                        </button>
                    </div>
                    <div className="amenities-btn-next">
                        <button
                            className="amenities-btn__slider amenities-btn__slider__right "
                            onClick={next}
                        >
                            <img
                                src={`${process.env.REACT_APP_MEDIA}/media/images/icons/bnt_arrow_right.svg`}
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VideoPage;
