import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

function Footer() {
    const [showList, setShowList] = useState(false);
    const menus = [
        {
            title: "Location",
            url: "/location",
        },
        {
            title: "Masterplan",
            url: "/master-plan",
        },
        {
            title: "Apartments",
            url: "/apartments",
        },
        {
            title: "Amenities",
            url: "/amenities",
        },
        {
            title: "Media",
            url: "/media",
        },
    ];

    const leftMenus = [
        {
            title: "Location",
            url: "/location",
        },
        {
            title: "Masterplan",
            url: "/master-plan",
        },
    ];
    const rightMenus = [
        {
            title: "Amenities",
            url: "/amenities",
        },
        {
            title: "Media",
            url: "/media",
        },
    ];
    return (
        <>
            <div className="section-footer">
                <Link to="/apartments">
                    <img
                        className="section-footer__logo"
                        src={`${process.env.REACT_APP_MEDIA}/media/images/icons/logo.svg`}
                        alt=""
                    />
                    <p className="section-footer__middle">Apartment</p>
                </Link>
                <ul className="section-footer__nav-left">
                    {leftMenus &&
                        leftMenus.map((menu, index) => (
                            <li key={index.toString()}>
                                <NavLink
                                    to={menu.url}
                                    activeClassName="list-active"
                                >
                                    {menu.title}
                                </NavLink>
                            </li>
                        ))}
                </ul>

                <ul className="section-footer__nav-right">
                    {rightMenus &&
                        rightMenus.map((menu, index) => (
                            <li key={index.toString()}>
                                <NavLink
                                    to={menu.url}
                                    activeClassName="list-active"
                                >
                                    {menu.title}
                                </NavLink>
                            </li>
                        ))}
                </ul>
            </div>
            <div
                className={`mobile-footer ${
                    showList && "mobile-footer--showList"
                }`}
            >
                <div className="mobile-footer__control">
                    {showList ? (
                        <img
                            onClick={() => setShowList(!showList)}
                            className="control__icon"
                            src={`${process.env.REACT_APP_MEDIA}/media/images/icons/close-list-mobile-menu.svg`}
                            alt=""
                        />
                    ) : (
                        <img
                            onClick={() => setShowList(!showList)}
                            className="control__icon"
                            src={`${process.env.REACT_APP_MEDIA}/media/images/icons/open-list-mobile-menu.svg`}
                            alt=""
                        />
                    )}

                    <Link to="/home">
                        <img
                            className="control__logo"
                            src={`${process.env.REACT_APP_MEDIA}/media/images/icons/logo-mobile-menu.svg`}
                            alt=""
                        />
                    </Link>
                </div>
                <ul className="mobile-footer__list">
                    {menus &&
                        menus.map((menu, index) => (
                            <ul key={index} className="mobile-footer__item">
                                <NavLink
                                    to={menu.url}
                                    activeClassName="list-active"
                                    onClick={() => setShowList(!showList)}
                                >
                                    {menu.title}
                                </NavLink>
                            </ul>
                        ))}
                </ul>
            </div>
        </>
    );
}

export default Footer;
