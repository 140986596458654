import request from "../request";

export const getApartmentDetail = async (params) =>
    request({
        url: `v1/Apartment/Read/0/${params}`,
        method: "GET",
    });

export const searchApi = async () =>
    request({
        url: "/v1/Apartment/SearchCriteria",
        method: "GET",
    });

export const filterApi = async (data) =>
    request({
        url: "/v1/Apartment/Read",
        method: "PATCH",
        data,
    });

export const getAvailability = async () =>
    request({
        url: "/v1/FloorLevel/Availability",
        method: "GET",
    });

export const getUpdateStatus = async (params) =>
    request({
        url: `/v1/Apartment/UpdateStatus/${params}`,
        method: "GET",
    });

export const updateStatus = async (data) =>
    request({
        url: `/v1/Apartment/UpdateStatus`,
        method: "POST",
        data,
    });
