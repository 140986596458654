import * as React from "react";
import { Modal } from "antd";

export function ModalLayout({
    children,
    className = "",
    visible,
    footer,
    handleOk,
    handleCancel,
    ...rest
}) {
    return (
        <Modal
            onOk={handleOk}
            onCancel={handleCancel}
            className={`modal-main ${className}`}
            visible={visible}
            centered
            footer={footer}
            closeIcon={
                <img
                    src={`${process.env.REACT_APP_MEDIA}/media/images/icons/icon-close-modal.svg`}
                    alt="img"
                />
            }
            {...rest}
        >
            {children}
        </Modal>
    );
}
