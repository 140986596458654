import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { data } from "./mock-data";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

const { Sider, Content } = Layout;

const AdminLayout = ({ children }) => {
    const dataUser = useSelector((state) => state.user.currentUser.fullName);
    const style = {
        width: "53%",
        margin: "0 auto",
        border: "none",
        position: "relative",
    };
    const [activeLink, setActiveLink] = useState(null);
    const location = useLocation();

    useEffect(() => {
        data.map((item) => {
            if (item.link === location.pathname) {
                setActiveLink(item.id);
            }
        });
    }, []);

    return (
        <div className="account-layout">
            {activeLink && (
                <Row>
                    <Col
                        trigger={null}
                        collapsible
                        style={{
                            backgroundColor: "white",
                            boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                            minHeight: "100vh",
                            borderRight: "1px solid #E4E4E4",
                        }}
                        span={4}
                    >
                        <div className="account-layout__logo">
                            <img
                                src={`${process.env.REACT_APP_MEDIA}/media/images/icons/admin-logo.svg`}
                                alt=""
                            />
                        </div>
                        <Menu style={style} defaultSelectedKeys={[activeLink]}>
                            {data.map((item) => (
                                <Menu.Item
                                    key={item.id}
                                    icon={<img src={item.icon} alt="img" />}
                                    className="item"
                                >
                                    <Link to={item.link}>
                                        <p>{item.desc}</p>
                                    </Link>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Col>
                    <Col span={20}>
                        <div className="account-layout__header">
                            <div className="account-layout__header__wrap">
                                <h4>Welcome, {dataUser}</h4>
                                <div className="account-layout__header__right">
                                    <Link to="/home">
                                        <div className="account-layout__header__back">
                                            <img
                                                src={`${process.env.REACT_APP_MEDIA}/media/images/icons/back-home.svg`}
                                                alt=""
                                            />
                                        </div>
                                        <p>Back to homepage</p>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <Content style={{ backgroundColor: "white" }}>
                            {children}
                        </Content>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default AdminLayout;
