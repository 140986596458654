import * as React from "react";
import { useState } from "react";
import { ModalLayout } from "./ModalLayout";
import { InputField } from "./../FieldCommon";
import { Row, Col } from "antd";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { signUpAction, handleCloseModalRegister } from "../../stores/user";
import { useDispatch, useSelector } from "react-redux";

const schemaValidate = yup.object().shape({
    firstName: yup.string().required("Please enter first name."),
    lastName: yup.string().required("Please enter first surname."),
    mobilePhone: yup
        .number()
        .required("Please enter mobile number.")
        .typeError("Please enter mobile number."),
    email: yup
        .string()
        .email("Please enter email address correct format.")
        .required("Please enter email address."),
});

export function ModalRegister({ visible, ...rest }) {
    const success = useSelector((state) => state.user.success);
    const errorMs = useSelector((state) => state.user.message);
    const dispath = useDispatch();
    const [ErrorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const initialValues = {
        firstName: "",
        lastName: "",
        mobilePhone: "",
        email: "",
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(schemaValidate),
    });

    const onSubmit = async (data) => {
        const value = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            mobilePhone: data.mobilePhone,
        };
        setSuccessMessage("Register success!");
        dispath(signUpAction(value));
    };

    React.useEffect(() => {
        if (success) {
            setTimeout(() => {
                dispath(handleCloseModalRegister());
            }, 800);
        } else {
            setErrorMessage(errorMs);
        }
    }, [success, errorMs, visible, dispath]);

    return (
        <ModalLayout className="modal-enquire" visible footer={null} {...rest}>
            <div className="modal-enquire__content">
                <h3 className="modal-enquire__title">WELCOME TO </h3>
                <h2 className="modal-enquire__title-main">Mesa</h2>
                <p className="modal-enquire__desc">Register agent</p>
                <div className="form-enquire">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={20}>
                            <Col span={12}>
                                <InputField
                                    label="First Name*"
                                    register={register}
                                    error={errors}
                                    name="firstName"
                                />
                            </Col>
                            <Col span={12}>
                                <InputField
                                    label="Surname*"
                                    register={register}
                                    error={errors}
                                    name="lastName"
                                />
                            </Col>
                        </Row>
                        <div className="row-model">
                            <div className="area-code-phone">+61</div>
                            <InputField
                                label="Mobile number*"
                                register={register}
                                error={errors}
                                name="mobilePhone"
                            />
                        </div>
                        <Row gutter={20}>
                            <Col span={24}>
                                <InputField
                                    label="Email address*"
                                    register={register}
                                    error={errors}
                                    name="email"
                                />
                            </Col>
                        </Row>
                        {ErrorMessage && (
                            <p className="errorMessage">{ErrorMessage}</p>
                        )}
                        <p className="successMessage">{successMessage}</p>
                        <button type="submit" className="btn-send">
                            Register
                        </button>
                    </form>
                </div>
            </div>
        </ModalLayout>
    );
}
