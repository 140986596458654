import React, { useEffect } from "react";
import {
    getMeAction,
    updateIsFirstAccess,
    getConfigAction,
} from "../stores/user";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../helpers/tokenMain";

const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const isFirstAccess = useSelector((state) => state.user.isFirstAccess);

    useEffect(() => {
        if (isFirstAccess) {
            const accessToken = getToken();
            if (accessToken) {
                dispatch(getMeAction());
                dispatch(getConfigAction());
            } else {
                dispatch(updateIsFirstAccess());
            }
        }
    }, [dispatch, isFirstAccess]);

    return <>{children}</>;
};

export default AuthProvider;
