export const data = [
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/bg-home.png`,
        src: "//player.vimeo.com/video/609560290",
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/thumb-video-2.png`,
        src: "//player.vimeo.com/video/641802851",
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-1.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-2.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-3.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-4.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-5.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-6.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-7.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-8.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-9.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-10.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-11.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-12.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-13.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-14.png`,
    },
    {
        srcImg: `${process.env.REACT_APP_MEDIA}/media/images/DetailProduct/gallery-15.png`,
    },
];
