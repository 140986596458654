import React, { Component } from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import "./App.css";
import Routes from "./routes";
import i18n from "./services/i18n";
import store from "./stores";
import theme from "./styles/theme";

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <ThemeProvider theme={theme}>
                        <Routes />
                        <ToastContainer />
                    </ThemeProvider>
                </I18nextProvider>
            </Provider>
        );
    }
}

export default App;
