import { Dropdown } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { revertNameAvatar } from "../../../helpers/common";
import { ModalRegister } from "../../Modal/ModalRegister";
import { getToken } from "../../../helpers/tokenMain";
import { REFRESH_TOKEN_KEY } from "../../../utils/common";
import { logoutAction } from "../../../stores/user";

const Account = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModalRegister, setShowModalRegister] = useState(false);
    const closeModal = useSelector((state) => state.user.closeModalRegister);
    const authentication = useSelector((state) => state.user.authentication);
    const currentUser = useSelector((state) => state.user.currentUser);
    const configUser = useSelector((state) => state.user.config.development);
    const isMaster = useSelector((state) => state.user?.currentUser?.isMaster);

    const accountPopup = () => (
        <div className="account">
            {configUser && <p onClick={handleAccount}>My Account</p>}
            {isMaster && <p onClick={handleShowModalRegister}>Register User</p>}
            <p onClick={handleLogout}>Log Out</p>
        </div>
    );
    const handleAccount = () => {
        history.push("/admin/my-details");
    };

    const handleLogout = () => {
        const token = getToken(REFRESH_TOKEN_KEY);
        if (token) {
            dispatch(
                logoutAction({
                    search: token,
                })
            );
        }
    };

    const handleShowModalRegister = () => {
        setShowModalRegister(true);
    };

    const handleCancelModalRegister = () => {
        setShowModalRegister(false);
    };

    useEffect(() => {
        if (closeModal) setShowModalRegister(false);
    }, [closeModal]);

    return (
        <>
            {authentication && (
                <Dropdown
                    overlay={accountPopup}
                    placement="bottomCenter"
                    trigger={["click"]}
                    className="account-popup"
                >
                    {currentUser?.avatarImg ? (
                        <img src={currentUser?.avatarImg} alt="avatar" />
                    ) : (
                        <p>{revertNameAvatar(currentUser?.fullName)}</p>
                    )}
                </Dropdown>
            )}
            {showModalRegister && (
                <ModalRegister
                    visible={showModalRegister}
                    handleCancel={handleCancelModalRegister}
                />
            )}
        </>
    );
};

export default Account;
